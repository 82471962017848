<template>
  <div class="app-container">
    <!-- <nav>
      <div class="tips">
          <div class="text">商品信息管理</div>
          <el-input v-model="queryValue"></el-input>
          <el-button @click="initTable" type="primary" class="btn">查询</el-button>
      </div> -->
      <div class="filter-container" style="min-height: auto;width: 100%">
        <el-form :inline="true" :model="queryForm" ref="queryForm" class="form-inline tz" style="width: 100%">
          <el-row style="width: 100%">
            <!-- 开票企业 发票类型 购买方名称 开票类型 开票日期 开票金额 状态 失败原因-->
            <el-form-item label="开票企业" prop="companyName" style="width: 15%" label-width="40%" class="single_query">
              <el-input placeholder="请输入查询条件" v-model="queryForm.companyName" maxlength="50"  class="filter-item" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="开票日期" prop="invoiceTime" style="width: 15%" label-width="40%" class="single_query">
              <!-- <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceTime" maxlength="50"  class="filter-item" @keyup.enter.native="handleQuery"/> -->
              <el-date-picker
                style="width:100%"
                v-model="queryForm.invoiceTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="购买方名称" prop="buyerName" style="width: 15%" label-width="40%" class="single_query">
              <el-input placeholder="请输入查询条件" v-model="queryForm.buyerName" maxlength="50"  class="filter-item" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="开票金额" prop="invoiceAmount" style="width: 15%" label-width="40%" class="single_query">
              <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceAmount" maxlength="50"  class="filter-item" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item style="width: 15%" class="button_query">
            <el-button v-waves class="filter-item" style="margin-left: 10%;" type="primary" icon="el-icon-search" @click="handleQuery" ref="search" >搜索</el-button>
            <el-button v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-refresh" @click="handleReset()" >重置</el-button>
          </el-form-item>
          </el-row>
        </el-form>
      </div>
      
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
       <!-- :hideSinglePage='false' :selectionType='true'-->
      <comps-table :tableData="tableData" :tableHeader="tableHeader"   :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

    

    

  </div>
</template>

<script>
  import compsTable from "@/components/table/table";
  import axios from 'axios'
  export default {
    components: { compsTable },
    data() {
      return {
        isLoading: false,
        hasData: false,
        //开票企业 发票类型 购买方名称 开票类型 开票日期 开票金额 状态 失败原因
        tableHeader: [
          {prop: "companyName", label: "开票企业", center: 'left' ,},
          {prop:'fplxdm',label:'发票类型',center: 'left',
            render: (h, data) => {
                return (
                  <span>
                    {data.row.fplxdm=='026'?'增值税电子普通发票':'增值税电子专用发票'}
                  </span>
                );
              },
          },
          {prop:'buyerName',label:'购买方名称',center: 'left',},
          {prop:'issueType',label:'开票类型',center: 'left',
            render: (h, data) => {
                return (
                  <span>
                     {data.row.issueType=='0'?'正数发票':'负数发票'}
                  </span>
                );
              },
          },
          {prop:'invoiceTime',label:'开票日期',center: 'left',},
          { prop: "invoiceAmount", label: "开票金额", center: 'left',},
          { prop: "issueStatus", label: "状态", center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.issueStatus=='02' ?  '开具失败' :'开具成功'}
                </span>
              );
            },
          },
          {prop:'failReason',label:'失败原因',center: 'left',},
        ],
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 20,
        isdeling: false,
        multipleSelection:[],

         queryForm: {
          buyerName: '',    //购买方名称
          companyName: '',    //开票方名称
          invoiceTime: '',    //开票日期
          invoiceAmount: '',    //开票金额
        },

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        
      }
    },
    methods: {
      handleQuery(){
        console.log(this.queryForm.invoiceNoStart,'queryForm.invoiceNoStart');
        this.queryForm.page = 1
        this.initTable()
      },
      //重置
      handleReset() {
          this.queryForm = {
          buyerName: '',    //购买方名称
          companyName: '',    //开票方名称
          invoiceTime: '',    //开票日期
          invoiceAmount: '',    //开票金额
        },
        this.$refs.queryForm.resetFields()
        this.initTable()
      },
      add() {
        // einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
        this.$router.push('productInfo/add')
      },
      edit(row) {
        this.$router.push({
          path: 'productInfo/edit',
          query: {
            id: row.id
          }
        })
      },

      // handleSelectionChange(val) {
      //   this.multipleSelection = val;
      // },

    
      
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      // 商品列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("findInvoiceFail", {
          page: this.page,
          rows: this.pageSize,
          ...this.queryForm,
          userId: this.getToken('userId'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      
      
      
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 170px !important;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    margin-top: 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }


  .invoice-account{
     .el-tag{
       min-width: 3rem !important;
     }
   }
  .single_query{
    .el-form-item__content {
      width: 60% !important;
    }
  }
  .range-query{
    .el-form-item__content {
      width: 85% !important;
    }
    .filter-item{
      width: 45%;
    }
  }
  .tz {
    .el-form-item{
      margin-right: 1rem !important;
    }
  }
  .button_query{
    float: right;
    .el-form-item__content {
      width: 100% !important;
    }
  }
</style>
